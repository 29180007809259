// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ["output"]

  connect() {
    var colors = document.getElementById("advisorStyles").innerText
    var colors = JSON.parse(colors)
    $('.btn-advisor').css({
      "color": "white",
      "background-color": colors[1]
    });
    $('.advisor-badge').css({
      "color": "white",
      "background-color": colors[1]
    });
    $('.advisor-badge-outline').css({
      "color": colors[1],
      "background-color": "white"
    });
    $('.advisor-font-color').css({
      "color": colors[1]
    });
    $('.advisor-card').css({
      "color": colors[1]
    });
    $('.btn-advisor-outline').css({
      "color": colors[1],
      "background-color": "white",
      "border-color": colors[1]
    });
    $('#contactCard').css({
      "color": "white",
      "background-color": colors[1]
    });
    $('.advisor-background-color').css({
      "color": "white",
      "background-color": colors[1]
    });
    $('.main-content').css({
      "background-color": colors[0]
    });
    $('.btn-client').css({
      "color": "white",
      "background-color": colors[1]
    });
    $('.advisor-link').css({
      "color": colors[1]
    });
    $('.avatar-primary .avatar-initials').css({
      "background-color": colors[1]
    });
    $('.page-title').css({
      "color": colors[1]
    });
    $('.advisor-dark').css({
      "color": colors[1]
    });
    $('.advisor-form-border').css({
      "border": "1px solid " + colors[1]
    });
    $('.iconColor').css({
      "color": colors[1]
    });
    $('.alert alert-notice').css({
      "background-color": colors[1]
    });
    $('.alert-custom').css({
      "background-color": colors[1]
    });
    $('.alert-custom-faded').css({
      "background-color": colors[1],
      "background-color": colors[1],
    });
    $('.advisor').css({
      "background-color": colors[1],
      "color": "white"
    });
    $('.advisor-nav-link').css({
      "color": colors[1]
    });
    $('.form-text').css({
      "color": colors[1]
    });
    $('.form-text').css({
      "color": colors[1]
    });
    $('.main-sidebar .nav .nav-item:hover').css({
      "font-size": "100px"
    });
    $('.advisor-text').css({
      "color": colors[1]
    });
    $('.advisor-background-faded').css({
      "background-color": colors[0],
      "color": colors[1],
      "font-weight": "600"
    });
    // $('.paginate_button.current').css({
    //   "background-color": "red"
    // });
    $('.advisor-current-color').css({
      "color": "white",
      "background-color": colors[1]
    });
    $('.alert-advisor').css({
      "color": "white",
      "background-color": colors[1]
    });
    $(".advisor-link").hover(function() {
      $(this).css("color", colors[1]);
    }, function() {
      $(this).css("color", "#5a5757");
    });

    $(".btn-advisor-outline").hover(function() {
      $(this).css("background-color", colors[1]);
      $(this).css("color", "white");
    }, function() {
      $(this).css("color", colors[1]);
      $(this).css("background-color", "white");
    });

  }

  sidebarLink(event) {
  }



}
