// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ["output"]

  connect() {
    var colors = document.getElementById("advisorStyles").innerText
    $('.btn-advisor').css({
      "color": "white",
      "background-color": colors[1]
    });
    $('advisor-badge').css({
      "color": "white",
      "background-color": colors[1]
    });
    $('.advisor-font-color').css({
      "color": colors[1]
    });
    $('.advisor-card').css({
      "color": colors[1]
    });
    $('.btn-advisor-outline').css({
      "color": colors[1],
      "background-color": "white",
      "border-color": colors[1]
    });
    $('#contactCard').css({
      "color": "white",
      "background-color": colors[1]
    });
    $('.advisor-background-color').css({
      "color": "white",
      "background-color": colors[1]
    });
    $('.advisor-current-color').css({
      "color": "white",
      "background-color": colors[1]
    });
    // $('.advisor-link').css({"color": colors[1]});


    $(".advisor-link").hover(function() {
      $(this).css("color", colors[1]);
    }, function() {
      $(this).css("color", "#5a5757");
    });

    $(".btn-advisor-outline").hover(function() {
      $(this).css("background-color", colors[1]);
      $(this).css("color", "white");
    }, function() {
      $(this).css("color", colors[1]);
      $(this).css("background-color", "white");
    });

  }


  showSecondQuestionBatch() {

    var controllerAlertMessage = document.getElementById("controllerAlertMessage")
    if (controllerAlertMessage != null) {
      document.getElementById("controllerAlertMessage").style.display = "none";
    }

    document.getElementById("quoteApplicationInvalidMessage").style.display = "none";
    var firstQuestionBatch = document.getElementById("firstQuestionBatch");
    var secondQuestionBatch = document.getElementById("secondQuestionBatch");
    var thirdQuestionBatch = document.getElementById("thirdQuestionBatch");

    var email = document.getElementById('email').value;
    var emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var isEmailValid = emailPattern.test(email);

    var postalCode = document.getElementById('postalCode').value;
    var postalCodePattern = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    var isPostalCodeValid = postalCodePattern.test(postalCode);

    var industry = document.getElementById('industry').value;
    var business_name = document.getElementById('business_name').value;
    var city = document.getElementById('city').value;
    var address = document.getElementById('address').value;
    // var unit_number = document.getElementById('unitNumber').value;
    var months_in_business = document.getElementById('months_in_business').value;
    // }
    var province = document.getElementById('provinceOfBusiness').value;
    var noCoverage = document.getElementById('noCoverage').value;

    if (province == "" || province == "false") {

      document.getElementById("quoteApplicationInvalidMessage").style.display = "block";
      document.getElementById("quoteApplicationInvalidMessage").innerHTML = "We can currently only provide quotes to companies based in Ontario.";
    } else if (noCoverage == "" || noCoverage == "true") {
      document.getElementById("quoteApplicationInvalidMessage").style.display = "block";
      document.getElementById("quoteApplicationInvalidMessage").innerHTML = "We can currently only provide quotes to companies who don't have existing benefits coverage.";
    } else if (industry == "" || business_name == "" || email == "" || postalCode == "" || city == "" || address == "" || months_in_business == "") {
      document.getElementById("quoteApplicationInvalidMessage").style.display = "block";
      document.getElementById("quoteApplicationInvalidMessage").innerHTML = "All Fields Are Required";
    } else if (isEmailValid == false) {
      document.getElementById("quoteApplicationInvalidMessage").style.display = "block";
      document.getElementById("quoteApplicationInvalidMessage").innerHTML = "Please provide a valid email: email@domain.com";
    } else if (isPostalCodeValid == false) {
      document.getElementById("quoteApplicationInvalidMessage").style.display = "block";
      document.getElementById("quoteApplicationInvalidMessage").innerHTML = "Please provide a valid postal code: A1A 1A1";
    } else if (isEmailValid == true && isEmailValid == true) {
      firstQuestionBatch.style.display = "none";
      secondQuestionBatch.style.display = "block";
      window.scrollTo({
        top: 0
      })
    }

  }

  showThirdQuestionBatch() {
    document.getElementById("quoteApplicationInvalidMessage").style.display = "none";
    var firstQuestionBatch = document.getElementById("firstQuestionBatch");
    var secondQuestionBatch = document.getElementById("secondQuestionBatch");
    var thirdQuestionBatch = document.getElementById("thirdQuestionBatch");

    var male_staff = document.getElementById('application_male_staff').value;
    var female_staff = document.getElementById('application_female_staff').value;
    var single_staff = document.getElementById('application_single_count').value;
    var married_staff = document.getElementById('application_married_count').value;
    var average_age = document.getElementById('application_average_age').value;

    if (average_age > 65 || average_age < 21) {
      document.getElementById("quoteApplicationInvalidMessage").style.display = "block";
      document.getElementById("quoteApplicationInvalidMessage").innerHTML = "Sorry! The average age of the company must be between 21 and 65.";
      return false
    }

    if (male_staff < 0 || female_staff < 0 || single_staff < 0 || married_staff < 0) {
      document.getElementById("quoteApplicationInvalidMessage").style.display = "block";
      document.getElementById("quoteApplicationInvalidMessage").innerHTML = "Please make sure your company demographics are positive numbers!";
      return false
    }

    if (this.fiftyEmployeeMax(single_staff, married_staff, male_staff, female_staff) == false) {
      document.getElementById("quoteApplicationInvalidMessage").style.display = "block";
      document.getElementById("quoteApplicationInvalidMessage").innerHTML = "We can only provide quotes for companies who have 50 employees or less.";
      return false
    }

    if (male_staff == "" || female_staff == "" || single_staff == "" || married_staff == "" || average_age == "") {
      document.getElementById("quoteApplicationInvalidMessage").style.display = "block";
      document.getElementById("quoteApplicationInvalidMessage").innerHTML = "All Fields Are Required";
    } else if (this.employeeCountValidator(male_staff, female_staff, single_staff, married_staff) == false) {
      document.getElementById("quoteApplicationInvalidMessage").style.display = "block";
      document.getElementById("quoteApplicationInvalidMessage").innerHTML = "Looks like your male/female employee total doesn't match your single/married employees. Can you review your numbers and try again?";
    } else {

      secondQuestionBatch.style.display = "none";
      thirdQuestionBatch.style.display = "block";
      window.scrollTo({
        top: 0
      })
    }
  }

  showFourthQuestionBatch() {
    var fourthQuestionBatch = document.getElementById("fourthQuestionBatch");
    var thirdQuestionBatch = document.getElementById("thirdQuestionBatch");
    thirdQuestionBatch.style.display = "none";
    fourthQuestionBatch.style.display = "block";
  }

  backToFirstQuestionBatch() {
    var firstQuestionBatch = document.getElementById("firstQuestionBatch");
    var secondQuestionBatch = document.getElementById("secondQuestionBatch");
    firstQuestionBatch.style.display = "block";
    secondQuestionBatch.style.display = "none";
  }

  backToSecondQuestionBatch() {
    var secondQuestionBatch = document.getElementById("secondQuestionBatch");
    var thirdQuestionBatch = document.getElementById("thirdQuestionBatch");
    secondQuestionBatch.style.display = "block";
    thirdQuestionBatch.style.display = "none";
  }

  backToThirduestionBatch() {
    var thirdQuestionBatch = document.getElementById("thirdQuestionBatch");
    var fourthQuestionBatch = document.getElementById("fourthQuestionBatch");
    thirdQuestionBatch.style.display = "block";
    fourthQuestionBatch.style.display = "none";
  }

  showDisabilityQuestions() {
    var value = event.currentTarget.value
    var payroll = document.getElementById("payrollQuestion");
    var waitingPeriod = document.getElementById("waitingPeriodQuestion");
    var disabililtyRate = document.getElementById("disabililtyRateQuestion");

    if (value == "true") {
      payroll.style.display = "block";
      waitingPeriod.style.display = "block";
      document.getElementById("payrollField").setAttribute("required", "true");

      if (document.getElementById("disabililtyRateField") != null) {
        document.getElementById("disabililtyRateField").setAttribute("required", "true");
        disabililtyRate.style.display = "block";
      }
    }

    if (value == "false") {
      document.getElementById("payrollField").removeAttribute("required")
      payroll.style.display = "none";
      waitingPeriod.style.display = "none";

      if (document.getElementById("disabililtyRateField") != null) {
        document.getElementById("disabililtyRateField").removeAttribute("required")
        disabililtyRate.style.display = "none";
      }
    }
  }

  submitFormRemotely(event) {
    var payroll = document.getElementById("payrollField").value;

    if (payroll == "") {
      document.getElementById("quoteApplicationInvalidMessage").innerHTML = "If adding disability, total company payroll is required.";
    }


    var life_insurance = document.getElementById('application_life_insurance').value;
    var health = document.getElementById('application_health').value;
    var drug_cap = document.getElementById('application_drug_cap').value;
    var vision = document.getElementById('application_vision').value;
    var paramedicals_amount = document.getElementById('application_paramedicals_amount').value;
    var paramedicals = document.getElementById('application_paramedicals').value;
    // var orthotics = document.getElementById('application_orthotics').value;
    var dental = document.getElementById('application_dental').value;
    var dental_percent = document.getElementById('application_dental_percent').value;
    var recall_period = document.getElementById('application_recall_period').value;
    var coverage = [life_insurance, health, drug_cap, vision, paramedicals_amount, paramedicals, dental, dental_percent, recall_period]

    if (payroll == "") {
      document.getElementById("quoteApplicationInvalidMessage").style.display = "block";
      document.getElementById("quoteApplicationInvalidMessage").innerHTML = "Payroll Is Required";
    }

    else if (coverage.includes("")) {
      document.getElementById("quoteApplicationInvalidMessage").style.display = "block";
      document.getElementById("quoteApplicationInvalidMessage").innerHTML = "All Fields Are Required";
    } else {
      $("#quoteForm").trigger('submit.rails');
    }
  }


  hoverOver(event) {

    var colors = document.getElementById("advisorStyles").innerText
    $("[id^=advisorQuoteCard]").hover(
      function(e) {
        $(this).css("border-color", colors[1]);
      }, // over
      function(e) {
        $(this).css("border-color", "rgb(223, 223, 223)");
      } // out
    );

  }

  employeeCountValidator(male_staff, female_staff, single_staff, married_staff) {
    male_staff = parseInt(male_staff)
    female_staff = parseInt(female_staff)
    single_staff = parseInt(single_staff)
    married_staff = parseInt(married_staff)
    if (male_staff + female_staff != single_staff + married_staff) {
      return false
    } else {
      return true
    }
  }

  advisorClientValidations(event) {
    var male_staff = document.getElementById('user_application_male_staff').value;
    var female_staff = document.getElementById('user_application_female_staff').value;
    var single_staff = document.getElementById('user_application_single_count').value;
    var married_staff = document.getElementById('user_application_married_count').value;
    if (this.employeeCountValidator(male_staff, female_staff, single_staff, married_staff) == false) {
      window.scrollTo({
        top: 0
      })
      document.getElementById("quoteApplicationInvalidMessage").style.display = "block";
      document.getElementById("quoteApplicationInvalidMessage").innerHTML = "Looks like your male/female employee total doesn't match your single/married employees. Can you review your numbers and try again?";
    } else {
      $("#new_user").trigger('submit.rails');
    }
  }

  showApplication(event) {
    this.clearApplicationCards()
    var chosenApplicationId = event.currentTarget.id
    document.getElementById("applicationCard-" + chosenApplicationId).style.display = "block"
  }

  clearApplicationCards() {
    var applicationCards = document.getElementsByClassName("application-card")
    // applicationCards

    for (var i = 0; i < applicationCards.length; i++) {
      applicationCards[i].style.display = "none"
    }
  }

  fiftyEmployeeMax(single, married, male, female) {
    if ((Number(single) + Number(married)) > 50) {
      return false
    } else if ((Number(male) + Number(female)) > 50) {
      return false
    } else {
      return true
    }
  }

  unlockApplication(event) {
  }

  advisorLiveQuoteValidation(event) {
    var form = document.getElementById("new_user");
    if (form.checkValidity() == false) {
      window.scrollTo({
        top: 0
      })
      document.getElementById("liveQuoteInvalidMessage").style.display = "block";
    } else {
      self.advisorClientValidations(event)
    }
  }

  submitAddClientFormRemotely(event) {
    debugger;
    // getting add client ids to do manual validation
    var company_name = document.getElementById('none').value;
    var email = document.getElementById('user_email').value;
    var first_name = document.getElementById('user_first_name').value;
    var last_name = document.getElementById('user_last_name').value;
    // var cellphone = document.getElementById('user_cellphone').value;
    // var client_status = document.getElementById('user_client_status').value;

    var address = document.getElementById('user_application_address').value;
    var city = document.getElementById('user_application_city').value;
    var province = document.getElementById('user_application_province_of_business').value;
    var postalCode = document.getElementById('postalCode').value;

    var provider = document.getElementById('user_costs_provider').value;
    var single_amount = document.getElementById('user_costs_single_amount').value;
    var family_amount = document.getElementById('user_costs_family_amount').value;
    var male_staff = document.getElementById('user_application_male_staff').value;
    var female_staff = document.getElementById('user_application_female_staff').value;
    var single_count = document.getElementById('user_application_single_count').value;
    var married_count = document.getElementById('user_application_married_count').value;
    var average_age = document.getElementById('user_application_average_age').value;
    var life_insurance = document.getElementById('user_application_life_insurance').value;
    var health = document.getElementById('user_application_health').value;
    var drug_cap = document.getElementById('user_application_drug_cap').value;
    var vision = document.getElementById('user_application_vision').value;
    var paramedicals_amount = document.getElementById('user_application_paramedicals_amount').value;
    var paramedicals = document.getElementById('user_application_paramedicals').value;
    var dental = document.getElementById('user_application_dental').value;
    var dental_percent = document.getElementById('user_application_dental_percent').value;
    var recall_period = document.getElementById('user_application_recall_period').value;
    // var policy_number = document.getElementById('user_application_policy_number').value;

    var payroll = document.getElementById("payrollField").value;

    var coverage = [company_name, email, first_name, last_name, address, city, province, postalCode, provider, single_amount, family_amount, male_staff, female_staff, single_count, married_count, average_age, life_insurance, health, drug_cap, vision, paramedicals_amount, paramedicals, dental, dental_percent, recall_period]
    var emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var isEmailValid = emailPattern.test(email);

    if (coverage.includes("")) {
      window.scrollTo({
        top: 0
      })
      document.getElementById("quoteApplicationInvalidMessage").style.display = "block";
      document.getElementById("quoteApplicationInvalidMessage").innerHTML = "All Fields Are Required";
      return false
    }

    if (isEmailValid == false) {
      window.scrollTo({
        top: 0
      })
      document.getElementById("quoteApplicationInvalidMessage").style.display = "block";
      document.getElementById("quoteApplicationInvalidMessage").innerHTML = "Please provide a valid email: email@domain.com";
      return false
    }

    if (average_age > 65 || average_age < 21) {
      window.scrollTo({
        top: 0
      })
      document.getElementById("quoteApplicationInvalidMessage").style.display = "block";
      document.getElementById("quoteApplicationInvalidMessage").innerHTML = "Sorry! The average age of the company must be between 21 and 65.";
      return false
    }


    else if (this.employeeCountValidator(male_staff, female_staff, single_count, married_count) == false) {
      window.scrollTo({
        top: 0
      })
      document.getElementById("quoteApplicationInvalidMessage").style.display = "block";
      document.getElementById("quoteApplicationInvalidMessage").innerHTML = "Looks like your male/female employee total doesn't match your single/married employees. Can you review your numbers and try again?";
    } else {
      $("#addClientForm").trigger('submit.rails');
    }
  }

  showPocketPillsField() {
    var value = event.currentTarget.value
    if (value == false || value == "100%")  {
      document.getElementById("pocketPillsField").style.display = "none";
    } else if (value != "100%") {
      document.getElementById("pocketPillsField").style.display = "block";
    }

  }

  hoverOverGroupBenefitz(event) {
    var colors = JSON.parse(document.getElementById("advisorStyles").innerText)
    this.element.style.border = "3px solid " + colors[1];
    this.element.style.borderRadius = "0.625rem";
  }


  leaveGroupBenefitz(event) {
    var colors = JSON.parse(document.getElementById("advisorStyles").innerText)
    this.element.style.border = "none";

  }

  selectGroupBenefitzPlan(event) {
    this.clearGroupBenefitzCards()
    var colors = JSON.parse(document.getElementById("advisorStyles").innerText)
    var selectedDiv = event.currentTarget
    selectedDiv.setAttribute("id", "selectedCard");
    document.getElementById("plan_tier").value = selectedDiv.dataset.value
    selectedDiv.style.border = "5px solid " + colors[1];
    selectedDiv.style.borderRadius = "0.3rem";
  }

  clearGroupBenefitzCards() {
    var groupBenefitzCards = document.getElementsByClassName("group-benefitz-card")
    // groupBenefitzCards

    for (var i = 0; i < groupBenefitzCards.length; i++) {
      groupBenefitzCards[i].style.border = "none"
      groupBenefitzCards[i].removeAttribute("id");
    }
  }

  submitGroupBenefitzForm(event) {

    debugger;
    let province = document.getElementById("plan_province").value
    let coverage_type = document.getElementById("plan_coverage_type").value
    let tier = document.getElementById("plan_tier").value
    let add_ons = document.getElementById("plan_add_ons").value
    let plan_type = document.getElementById("plan_plan_type").value


    if (province != '' && coverage_type != '' && tier != '') {

    $.ajax({
      url: "/plans",
      method: 'POST',
      data: { "tier": tier, "province": province, "coverage_type": coverage_type, "add_ons": add_ons, "plan_type": plan_type},
      });
    }

    }
}
