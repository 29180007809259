// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ["output"]

  connect() {
    var colors = document.getElementById("advisorStyles").innerText
    $('.main-content').css({"color": colors[1], "background-color": colors[0]});
  }

  ltdDissmisal() {
    $.ajax({
      type: "POST",
      url: "/users/ltd_dismissal",
      data: {
      },
      success: function() {
      }
    })
  }

  authenticateBamboo(event) {
    var email = document.getElementById("bamboo_email").value
    var company = document.getElementById("bamboo_company").value
    var password = document.getElementById("bamboo_password").value

    $.ajax({
      type: "POST",
      url: "/test_integration",
      data: {
        "email": email,
        "company": company,
        "password": password,
      },
      success: function() {
        var bambooAuthCard = document.getElementById("bambooAuthorizationCard");
        var newEmployeeContainer = document.getElementById("NewEmployeeFormContainer");
        $("#ReloadThis").html(location.href+" #ReloadThis>*","");
        newEmployeeContainer.style.display = "none"
        bambooAuthCard.style.display = "block"
        location.reload();
      }
    })

  }

  getBambooEmployees(event) {
    $.ajax({
      type: "GET",
      url: "/bamboo_employee_import",
    })
  }

}
