// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {

    if(window.location.href.indexOf("add-employee") > -1) {
      var bambooAuthCard = document.getElementById("bambooAuthorizationCard");
      var newEmployeeContainer = document.getElementById("NewEmployeeFormContainer");
      // newEmployeeContainer.style.display = "block"
      // bambooAuthCard.style.display = "block"
      var bamboo_auth = document.getElementById("bambooAuthCheck").innerText
      if (bamboo_auth == "true") {
        var bambooContainer = document.getElementById("bambooAuthorizationCard");
        bambooContainer.style.display = "block"
        var newEmployeeFormContainer = document.getElementById("manualEmployeeForm");
        newEmployeeFormContainer.style.display = "none"
      }
    }

  }




  clearAllFormContainers() {
    var formContainers = document.getElementsByClassName("hide-container")
    for (var i = 0; i < formContainers.length; i++) {
      // console.log(formContainers[i])
      formContainers[i].style.display = "none"
    }
  }

  toggleNewEmployeeFormContainer() {
    this.clearAllFormContainers()
    var newEmployeeFormContainer = document.getElementById("manualEmployeeForm");
    newEmployeeFormContainer.style.display = "block"
    // var importContainer = document.getElementById("importEmployeeContainer");
    // var bambooContainer = document.getElementById("bambooAuthorizationCard");
    // var peopleContainer = document.getElementById("peopleAuthorizationCard");
    // importContainer.style.display = "none"
    // bambooContainer.style.display = "none"
    // peopleContainer.style.display = "none"
  }

  toggleEmployeeImportContainer() {
    this.clearAllFormContainers()
    // var manualEmployeeContainer = document.getElementById("manualEmployeeForm");
    var csvContainer = document.getElementById("importEmployeeContainer");
    // var bambooContainer = document.getElementById("bambooAuthorizationCard");
    // var peopleContainer = document.getElementById("peopleAuthorizationCard");
    // var breatheContainer = document.getElementById("breathehrAuthorizationCard");
    csvContainer.style.display = "block"
    // bambooContainer.style.display = "none"
    // peopleContainer.style.display = "none"
    // breatheContainer.style.display = "none"
    // manualEmployeeContainer.style.display = "none"
  }

  toggleBambooFormContainer() {
    this.clearAllFormContainers()
    var bambooContainer = document.getElementById("bambooAuthorizationCard");
    bambooContainer.style.display = "block"
    var importContainer = document.getElementById("importEmployeeContainer");
    var peopleContainer = document.getElementById("peoplehrAuthorizationCard");
    var breatheContainer = document.getElementById("breathehrAuthorizationCard");
    importContainer.style.display = "none"
    peopleContainer.style.display = "none"
    breatheContainer.style.display = "none"
  }

  togglePeopleFormContainer() {
    this.clearAllFormContainers()
    var peopleContainer = document.getElementById("peoplehrAuthorizationCard");
    peopleContainer.style.display = "block"
    var importContainer = document.getElementById("importEmployeeContainer");
    var bambooContainer = document.getElementById("bambooAuthorizationCard");
    var breatheContainer = document.getElementById("breathehrAuthorizationCard");
    importContainer.style.display = "none"
    bambooContainer.style.display = "none"
    breatheContainer.style.display = "none"
  }

  toggleBreatheFormContainer() {
    this.clearAllFormContainers()
    var breatheContainer = document.getElementById("breathehrAuthorizationCard");
    breatheContainer.style.display = "block"
    var importContainer = document.getElementById("importEmployeeContainer");
    var bambooContainer = document.getElementById("bambooAuthorizationCard");
    var peopleContainer = document.getElementById("peoplehrAuthorizationCard");
    importContainer.style.display = "none"
    bambooContainer.style.display = "none"
    peopleContainer.style.display = "none"
  }



}
